export default {
    BASIC_HEADER_HEIGHT: 72,
    SECONDARY_HEADER_HEIGHT: 56,
    HEADER_CONTENT_HORIZONTAL_PADDING: 32,
    MOBILE_MINI_SITE_BASIC_HEADER_HEIGHT: 52,
    MOBILE_MINI_SITE_SECONDARY_HEADER_HEIGHT: 44,
    SIGNUP_POPUP_SCREEN_RIGHT_MARGIN: 16,
    SIGNUP_POPUP_SCREEN_WIDTH: 346,
    SIGNUP_POPUP_SCREEN_HEIGHT: 404,
    PRODUCTS_SWITCHER_SCREEN_WIDTH: 300,
    PRODUCTS_SWITCHER_NEW_USERS_SCREEN_HEIGHT: 375,
    PRODUCTS_SWITCHER_EXISTING_USERS_SCREEN_HEIGHT: 270,
    PRODUCTS_SWITCHER_SCREEN_RIGHT_MARGIN: 32
};
